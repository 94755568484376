import { useAuthorityState } from '@context/authority/AuthorityContext';

export const useShortformFunction = () => {
  const { myFunctions, myPrivileges, isGlobalSuperAdmin, administrator } = useAuthorityState();
  const isShortformGroup = myFunctions.SHORTFORM_GROUP;
  const isShortformReview = myFunctions.SHORTFORM_REVIEW;
  const isShortformCreator = myFunctions.SHORTFORM_CREATOR;
  const isShortformManage = myPrivileges.isShortformManage;
  const isShortformUgc = myFunctions.SHORTFORM_UGC;
  const enableShortForm = isGlobalSuperAdmin || myFunctions.SHORT_FORM;
  const isShortformReviewer = !administrator?.authority?.privileges.includes('SHORTFORM_MANAGE');

  return {
    isShortformGroup,
    isShortformReview,
    isShortformCreator,
    isShortformManage,
    isShortformUgc,

    enableShortForm,
    isShortformReviewer,
  };
};
