import { getCustomerChildren, recordLatestCustomer } from '@api/shoplive/auth';
import { setSessionStorageCustomerId } from '@common/helper/auth';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AutoComplete, Input } from 'antd';
import styled from 'styled-components';
import { ReactComponent as ArrowDownIcon } from '@assets/svg/arrow-down-s-line.svg';
import { useAuthorityState } from '@context/authority/AuthorityContext';

const StyledInput = styled(Input)`
  border-radius: 5px !important;
  height: 100%;

  .ant-input {
    font-size: 13px;

    &::placeholder {
      color: ${({ theme }) => theme.newColor.Black500} !important;
    }
  }
  .ant-input-suffix {
    .arrow-ic {
      display: flex;
      align-items: center;
      padding-right: 12px;
    }
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: hsla(0, 0%, 20%, 1);
      }
    }
  }
`;

const CustomerSelector: React.FC = () => {
  const { administrator } = useAuthorityState();

  const currentCustomerId = administrator?.currentCustomerId;
  const currentCustomerName = administrator?.currentCustomerName;
  const [options, setOptions] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');

  const refAutocomplete = useRef(null);

  //자식 고객사 명단 가져오기.
  const { data: childrenResult } = getCustomerChildren();

  const children = childrenResult?.results ?? [];
  const optionsAll = useMemo(
    () =>
      children.map((child) => ({
        value: child.customerId,
        label: child.name,
        identifier: child.identifier,
      })),
    [children],
  );

  const onSelect = useCallback(
    (val: any) => {
      if (val == currentCustomerId) {
        setInputValue('');
        return;
      }

      for (const opt of optionsAll) {
        if (opt.value === val) {
          setInputValue(opt.label);
          break;
        }
      }

      recordLatestCustomer(val)
        .then(() => {
          setSessionStorageCustomerId(val);
          window.location.href = '/';
        })
        .catch((error) => {
          alert(error);
        });
    },
    [optionsAll],
  );

  const onChangeInputValue = useCallback(
    (value: any) => {
      setInputValue(value);
      const val = value.trim();

      if (val.length === 0 && options.length > 0) {
        setOptions([]);
      } else if (val.length > 0 && options.length === 0) {
        setOptions([...optionsAll]);
      }
    },
    [optionsAll, options],
  );

  const onClickSuffix = (event: any) => {
    event.preventDefault();
    if (options.length === 0) {
      setOptions([...optionsAll]);
    } else if (inputValue.trim().length === 0) {
      setOptions([]);
    }
  };

  const filterOptionHandler = (inputValue: string, option: any) => {
    return (
      option?.label?.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
      option?.identifier?.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    );
  };

  useEffect(() => {
    const dom = document.querySelector('.customer-selector input');
    dom?.setAttribute('autocomplete', 'one-time-code');
  }, [refAutocomplete]);

  return children.length > 1 ? (
    <S_AutoComplete
      className="customer-selector"
      onBlur={() => {
        if (inputValue.trim().length === 0 && options.length > 0) {
          setOptions([]);
        }
      }}
      options={options}
      value={inputValue}
      onSelect={onSelect}
      onChange={onChangeInputValue}
      filterOption={filterOptionHandler}
      defaultValue={currentCustomerName}
      ref={refAutocomplete}
      popupClassName="c-select"
    >
      <StyledInput
        placeholder={currentCustomerName}
        autoComplete="one-time-code"
        suffix={
          <div onMouseDown={onClickSuffix} className="arrow-ic">
            <ArrowDownIcon />
          </div>
        }
      />
    </S_AutoComplete>
  ) : null;
};

export default CustomerSelector;
const S_AutoComplete = styled(AutoComplete)`
  border-radius: 5px !important;
  overflow: visible;
  display: flex;
  height: 36px;
  box-sizing: border-box;
  border: 1px solid hsla(0, 0%, 89%, 1);
  max-width: 160px;
  min-width: 60px;

  .ant-input-affix-wrapper {
    padding: 8px 0 8px 12px;
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
  }

  .ant-input-affix-wrapper,
  .ant-input {
    background-color: hsla(0, 0%, 100%, 1) !important;
  }
`;
