import { ErrorInfo, Suspense, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { getMe } from '@api/shoplive/auth';
import { initLanguageCode, languageManager } from '@common/helper/languages';
import { getLoginPath, getToken, saveToken, setSessionStorageCustomerId } from '@common/helper/auth';
import { getRouteByAuthority } from '@common/helper/route';
import LoadingPage from '@common/loading/loading-page';
import { doesPathMatchPatterns } from '@common/utils/path-matcher';
import AuthorityProvider, { useAuthorityDispatch, useAuthorityState } from '@context/authority/AuthorityContext';
import { setCurrentAbbr, setCurrentTimezone } from '@common/helper/date';
import DefaultLayout from './layout';
import { getPluginJSUrl, getServerRegion, getShortformJSUrl, PHASE, phase, SERVER_REGION } from '@common/utils/phase';
import { useRouter } from '@hooks/useRouter';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@components/ErrorFallback';
import { useShortFormStore } from '@store/shortFormStore';
import { ConfigProvider } from 'antd';
import koKR from 'antd/lib/locale/ko_KR';
import enUS from 'antd/lib/locale/en_US';
import jaJP from 'antd/lib/locale/ja_JP';
import { Locale } from 'antd/lib/locale-provider';
import RegionManager from '@common/regions';
import { useAiClipAdminGuard } from '@hooks/ai-clip/useAiClipAdminGuard';
import { isString } from 'lodash';

const excludeLayoutPage = [
  '#/ieUnsupported',
  '#/login',
  '#/login/studio',
  '#/login/oauth',
  '#/login/fail',
  '#/login/forgot-password',
  '#/register/setup-admin',
  '#/register/renew-password',
  '#/campaigns/:id/console',
  '#/campaigns2/:id/console',
  '#/campaigns/:id/console_v1',
  '#/campaigns/:id/console_stable',
  '#/campaigns/:id/prompter',
  '#/campaigns2/:id/prompter',
  '#/campaigns/:id/prompter_v1',
  '#/campaigns/:id/prompter_stable',
  '#/campaigns/:id/insights',
  '#/campaigns2/:id/insights',
  '#/campaigns/:id/replay',
  '#/campaigns2/:id/replay',
  '#/campaigns/:id/prompter-mobile',
  '#/campaigns2/:id/prompter-mobile',
  '#/studio',
  '#/monitor/l7/check',
  '#/cafe24',
  '#/cafe24app',
  '#/cafe24result',
  '#/gslogin',
];

const hideConsoleLog = () => {
  if (process.env.NODE_ENV === 'production') {
    window['console']['log'] = function () {};
    window['console']['debug'] = function () {};
    window['console']['error'] = function () {};
    window['console']['warn'] = function () {};
  }
};

const BaseComponent = () => {
  const onErrorLog = (error: Error, info: ErrorInfo) => {
    console.error('Caught an error:', error, info);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={onErrorLog}>
      <AuthorityProvider>
        <BaseLayoutComponent />
      </AuthorityProvider>
    </ErrorBoundary>
  );
};

export default BaseComponent;

const BaseLayoutComponent = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setLanguage, setAdministrator } = useAuthorityDispatch();

  const [isLoadingAuth, setIsLoadingAuth] = useState(true);
  const [isLoadingLang, setIsLoadingLang] = useState(false);
  const setShortformLoaded = useShortFormStore((state) => state.setShortformLoaded);

  const { pathname } = useRouter();
  const { language } = useAuthorityState();

  useAiClipAdminGuard();

  const locale = useMemo(() => {
    switch (language) {
      case 'ko':
        return { ...koKR, Form: { ...koKR.Form, optional: '(선택)' } } as Locale;
      case 'ja':
        return jaJP;
      default:
        return enUS;
    }
  }, [language]);

  // 최초 한번만 실행해야할 로직들.
  useLayoutEffect(() => {
    const isIE = /Trident|MSIE/.test(navigator.userAgent); // Trident: IE 11 엔진, 'MISE': IE 10 이하에서 사용
    if (isIE) {
      setIsLoadingAuth(false);
      setIsLoadingLang(false);
      navigate('/ieUnsupported', { replace: true });
      return;
    }

    // 플러그인/숏폼 JS 로드.
    const pluginUrl = getPluginJSUrl();
    const shortsUrl = getShortformJSUrl();

    const pluginScript = document.createElement('script');
    pluginScript.src = pluginUrl;
    document.head.appendChild(pluginScript);

    const shortsScript = document.createElement('script');
    shortsScript.src = shortsUrl;
    shortsScript.onload = () => setShortformLoaded(true);

    document.head.appendChild(shortsScript);

    // authorization 물고 들어온게 있으면 token 저장해둔다.
    const _authorization = searchParams.get('_authorization');
    if (_authorization) {
      saveToken(_authorization, null, null);
    }

    // query 에 명시적으로 _ci 가 오면, currentCustomerId 를 변경해준다.
    if (searchParams.get('_ci')) {
      setSessionStorageCustomerId(searchParams.get('_ci') as string);
    }

    // language code 물고 들어온게 있으면 랭귀지.
    const lang = searchParams.get('lang');
    if (lang) {
      initLanguageCode(lang);
    }

    if (getServerRegion(RegionManager.apiEndpoint) === SERVER_REGION.APNE2) {
      // ChannelService.loadScript();
    }

    const initializeApp = async () => {
      try {
        languageManager.reloadCurrentLanguageResource();
        setIsLoadingLang(!languageManager.currentResource);

        const refreshedLang = await languageManager.setCurrentLanguageAndRefresh();
        setIsLoadingLang(false);
        setLanguage(refreshedLang);
      } catch (error) {
        console.error(error);
      }
    };

    initializeApp();
  }, []);

  useEffect(() => {
    //// Me를 호출해서 자격이 있는지 알아온다.
    refreshAdministrator();
  }, []);

  const refreshAdministrator = async () => {
    if (
      pathname.startsWith('/login') ||
      pathname.startsWith('/register') ||
      pathname.startsWith('/studio') ||
      pathname.startsWith('/monitor/l7/check') ||
      pathname.startsWith('/cafe24app') ||
      pathname.startsWith('/cafe24result')
    ) {
      setIsLoadingAuth(false);
      return;
    }

    //accessToken 도 없고, 로그인 페이지가 아니라면, getMe 하지 말고 로그인 페이지로 이동하자.
    if (getToken() == null && !pathname.startsWith('/login')) {
      setIsLoadingAuth(false);
      navigate(getLoginPath(), { replace: true });
      return;
    }

    try {
      const me = await getMe();

      const roles: string[] = me?.authority?.roles ?? [];
      const isGlobalSuperAdmin = roles.includes('GLOBAL_SUPER_ADMIN');

      if (!isGlobalSuperAdmin) {
        hideConsoleLog();
      }

      setIsLoadingAuth(false);
      setAdministrator(me);
      setCurrentAbbr(me.timezoneAbbr || '');
      setCurrentTimezone(me.timezone || '');

      const routeByAuthority = getRouteByAuthority({
        administrator: me,
        pathname,
      });
      if (isString(routeByAuthority)) {
        navigate(routeByAuthority, { replace: true });
      }
    } catch (error) {
      hideConsoleLog();
      console.error(error);
      setIsLoadingAuth(false);
      setAdministrator(null);

      switch (phase) {
        case PHASE.PROD:
        case PHASE.STG: {
          if (!pathname.startsWith('/login')) {
            navigate(getLoginPath(), { replace: true });
          }
        }
      }
    }
  };

  const isExcludeLayout = doesPathMatchPatterns(location.hash, excludeLayoutPage);
  if (isLoadingAuth || isLoadingLang) {
    return <LoadingPage />;
  }

  return (
    <ConfigProvider locale={locale}>
      {isExcludeLayout ? (
        <Suspense>
          <Outlet />
        </Suspense>
      ) : (
        <DefaultLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DefaultLayout>
      )}
    </ConfigProvider>
  );
};
