import { useAuthorityState } from '@context/authority/AuthorityContext';
import { useRouter } from '@hooks/useRouter';
import { useEffect } from 'react';

/**
 * AI 관리자 권한(CUSTOMER_AI_CLIP_ADMIN)인 경우, 페이지 접근 허용/불가 처리.
 *
 * 다음 4가지 메뉴만 접근 가능.
 *  - AI Clip (/ai-clip)
 *  - 미디어 (/medias)
 *  - 어드민 가이드 (새탭)
 *  - 개발자 가이드 (새탭)
 */
export function useAiClipAdminGuard() {
  const { isAiClipAdmin } = useAuthorityState();
  const router = useRouter();

  useEffect(() => {
    if (isAiClipAdmin) {
      const allowedRoutes = ['/ai-clip', '/medias', '/user/settings', '/login', '/register', '/gslogin'];

      // `/ai-clip/usage` 같은 subroute가 존재하여 startsWith()로 판단.
      const isAllowed = allowedRoutes.some((menu) => router.pathname.startsWith(menu));

      if (!isAllowed) {
        router.replace('/ai-clip');
      }
    }
  }, [router.pathname]);
}
