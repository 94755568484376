import { LoadingOutlined } from '@ant-design/icons';
import CustomToolTip from '@components/_tooltip/CustomTooltip';
import { useConfirm } from '@hooks/useModalConfirm';
import { useTranslation } from '@hooks/useTranslation';
import { IconButton } from '@styles/design-system/button/IconButton';
import { Icon } from '@styles/design-system/icon/Icon';
import { Text } from '@styles/design-system/text/Text';
import { Spin } from 'antd';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { MultiProgressPopupStatus } from './MultiProgressPopup';
import { useMessage } from '@hooks/useMessage';
import { aiClipFlag } from '@components/ai-clip/ai-clip.constant';

type MultiProgressPopupItemProps = {
  label: string;
  status: MultiProgressPopupStatus;
  percent: number;
  texts: {
    cancelConfirmTitle: string; // e.g. '다운로드를 취소하시겠습니까?'
    cancelCompleteToast: string; // e.g. '다운로드가 취소되었습니다.'
  };
  onRemoveItem: () => void;
  onRetryItem: () => void;
};

export const MultiProgressPopupItem = ({
  label,
  status,
  percent,
  texts,
  onRemoveItem,
  onRetryItem,
}: MultiProgressPopupItemProps): ReactElement => {
  const { modalConfirm } = useConfirm();
  const { customMessage } = useMessage();
  const { t } = useTranslation();

  const clickActionButton = () => {
    if (status === MultiProgressPopupStatus.IN_PROGRESS) {
      modalConfirm(texts.cancelConfirmTitle, {
        onOk() {
          onRemoveItem();
          customMessage('success', {
            content: texts.cancelCompleteToast,
          });
        },
      });
    } else {
      if (aiClipFlag.preparingDownload && status === MultiProgressPopupStatus.FAILED) {
        onRetryItem();
      } else {
        onRemoveItem();
        customMessage('success', {
          content: t('multi_progress_popup.toast.remove_complete'),
        });
      }
    }
  };

  const isReady = status === MultiProgressPopupStatus.READY;
  const isInProgress = status === MultiProgressPopupStatus.IN_PROGRESS;
  const isFailed = status === MultiProgressPopupStatus.FAILED;
  const isSuccess = status === MultiProgressPopupStatus.SUCCESS;

  const floatingIconSize = 20;

  const tooltipTextMap: Record<MultiProgressPopupStatus, string> = {
    [MultiProgressPopupStatus.READY]: '', // Not used.
    [MultiProgressPopupStatus.IN_PROGRESS]: t('button.cancel'),
    [MultiProgressPopupStatus.FAILED]: aiClipFlag.preparingDownload
      ? t('button.retry')
      : t('multi_progress_popup.tooltip.remove_from_list'),
    [MultiProgressPopupStatus.SUCCESS]: t('multi_progress_popup.tooltip.remove_from_list'),
  };

  return (
    <S_Host>
      <Text variant="BodyS" color="Black700">
        {label}
      </Text>

      {!isReady && (
        <CustomToolTip title={tooltipTextMap[status]} trigger="hover">
          <S_IconRelative>
            <IconButton
              style={{ width: 28, height: 28, border: 'none' }}
              className="action-button"
              icon={aiClipFlag.preparingDownload && isFailed ? 'refresh' : 'closeLine'}
              size="medium"
              variant="basic"
              onClick={clickActionButton}
            />

            <S_FloatingIcon className="floating-icon">
              {isInProgress && <Spin indicator={<LoadingOutlined style={{ fontSize: floatingIconSize }} />} />}
              {isFailed &&
                (aiClipFlag.preparingDownload ? (
                  <Icon icon="refresh" size={floatingIconSize} color="Black700" />
                ) : (
                  <Icon icon="errorWarningLine" size={floatingIconSize} color="AlertDestructive" />
                ))}
              {isSuccess && <Icon icon="checkFill" size={floatingIconSize} color="AlertPositive" />}
            </S_FloatingIcon>
          </S_IconRelative>
        </CustomToolTip>
      )}

      {isInProgress && <S_BottomProgress style={{ width: `${percent}%` }} />}
    </S_Host>
  );
};

const S_Host = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: auto;
  min-height: 47px;
  padding: 14px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.newColor.Black100};
`;

const S_IconRelative = styled.div`
  position: relative;
  cursor: pointer;

  .action-button {
    visibility: hidden;
    transition: visibility 0s;
  }

  &:hover {
    .action-button {
      visibility: visible;
    }
    .floating-icon {
      display: none;
    }
  }
`;

const S_FloatingIcon = styled.div`
  display: inline-flex;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const S_BottomProgress = styled.div`
  position: absolute;
  left: 0;
  bottom: -1px;
  border-bottom: 2px solid ${({ theme }) => theme.newColor.Black};
  transition: width 0.5s ease-in-out;
`;
