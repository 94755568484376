import { TMainAdministrator } from '@localTypes/administrator';
import { isString } from 'lodash';

export function getRouteByAuthority({
  administrator,
  pathname,
}: {
  administrator: TMainAdministrator | null;
  pathname?: string;
}): string | null {
  const privileges = administrator?.authority?.privileges ?? [];
  const roles = administrator?.authority?.roles ?? [];

  const isAiClipAdmin = roles.includes('CUSTOMER_AI_CLIP_ADMIN');

  if (isString(pathname)) {
    const shouldGoCampaigns = pathname === '/' && privileges.includes('CAMPAIGN_LIST');
    const shouldGoShortforms = pathname === '/' && privileges.includes('SHORTFORM_LIST');

    if (shouldGoCampaigns) {
      return '/campaigns';
    } else if (shouldGoShortforms) {
      return '/short-forms';
    } else if (isAiClipAdmin) {
      return '/ai-clip';
    } else {
      return null;
    }
  } else {
    return isAiClipAdmin ? '/ai-clip' : '/campaigns2';
  }
}
