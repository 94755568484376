export type TKeyFrame = {
  cdnFrameUrl: string;
  duration: number;
  endAt: number;
  filename: string;
  filesize: number;
  frameCount: number;
  frameHeight: number;
  frameWidth: number;
  id: string;
  s3FrameUrl: string;
  sections: TFrameSection[];
  startAt: number;
};

export type TFrameSection = {
  s: number;
  e: number;
};

export type TReviewDecline = {
  shortformIds: string[] | React.Key[];
  reviewStatus: ReviewStatus;
  declinedReason?: string;
};

export type TShortsStatus = 'OPENED' | 'CLOSED' | 'RESERVED';
export interface TShortsList {
  adminId: string;
  shorts: TShorts;
  createdAt: number;
  customerId: string;
  endAt: number;
  id: string;
  name: string;
  sellerId: string | null;
  shortsCollectionId: string;
  srn: string;
  startAt: number;
  uid: string;
  updatedAt: number;
  shortsStatus: TShortsStatus;
  totalCommentCount: number;
  shortformReviewStatus?: ReviewStatus;
  displayPriority?: number;
  displayAreas?: string;
  declinedReason?: string;
  createAdminName?: string;
  updateAdminName?: string;
  updateAdminId?: string;
  shortsId?: string;
}

export interface TShortsFormList {
  results: TShortsList[];
  totalCount: number;
  _s: number;
}

export interface TShorts {
  shortsId: string;
  srn: string;
  startAt: null;
  endAt: null;
  shortsDetail: TShortsDetail;
  activity: TActivity;
  action: null;
  payload: null;
  cards?: TShortsCard[];
  shortsType: string;
  traceId: string;
  displayPriority?: string;
  campaign?: TShortsCampaign;
}

export interface TActivity {
  viewCount: number;
  likeCount: number;
  shareCount: number;
  commentCount: number;
  bookmarkCount: number;
  like: boolean;
  bookmark: boolean;
  productClickCount: number;
}

export enum PlayVideoType {
  HLS = 'HLS',
  MP4 = 'MP4',
}

export interface TShortsCard {
  duration: number;
  playCount?: number;
  playDuration?: number;
  source: string;
  videoId: string;
  campaignId?: null;
  clips: TClip[];
  videoUrl: null;
  previewVideoUrl: null;
  screenshotUrl: null;
  playVideoType: PlayVideoType;
  cardType: string;
  srn: string;
  externalVideoUrl?: string;
  externalVideoType?: string;
  aiClipId?: string;
  aiClipSectionId?: string;
  specifiedScreenshotUrl?: string;
  convertStatus: TShortformConvertStatus;
}
export type TShortformConvertStatus = 'READY' | 'IN_PROGRESS' | 'COMPLETE' | 'FAILED';

export interface TClip {
  title: string;
  from: number;
  to: number;
  idx?: number;
}

export type TMaskingClip = {
  from: number;
  to: number;
};

export interface TAuthor {
  name: string;
  profileUrl: string;
  action?: string;
  payload?: any;
}

export interface TShortsDetail {
  title: string;
  description?: string;
  author: TAuthor;
  tags?: string[];
  productCount: number;
  productBanner?: TProductBanner;
  products: TShortsProduct[];
  externalProducts?: TExternalProduct[];
  brand: TBrand;
  creator?: TShortformCreator;
  customerShortformType?: string;
  shortformCollections: TShortformCollections[];
  linkButton: Partial<TProductBanner>;
}

export interface TShortformCollections {
  id: string;
  title: string;
  reference?: string;
  hasMore?: boolean;
}

export interface TShortformCreator {
  uid: string;
  userId: string;
  customerCreatorType: string;
  apiType?: string;
  displayUserId?: string;
  withdrawn?: boolean;
}

export interface TExternalProduct {
  sku: string;
}

export interface TBrand {
  id: string;
  name: string;
  identifier: string;
  imageUrl: string;
}

export interface TProductBannerItem {
  adminId: string;
  adminName: string;
  createdAt: number;
  customerId: string;
  height: number;
  id: string;
  imageUrl: string | undefined;
  name: string;
  title: string | null | undefined;
  updatedAt: number;
  scheme: string;
  width: number;
}

export interface TShortsProduct {
  productId: string;
  name: string;
  brand: string;
  url: string;
  sku: string;
  imageUrl: string;
  currency: string;
  showPrice: boolean;
  originalPrice: number;
  discountPrice: number;
  discountRate: number;
  stockStatus: string;
  brandIdentifier?: string;
  description?: string;
}

export type TBarPosAndTime = {
  time: number;
  pos: number;
};

export interface TShortsCommentsData {
  comments: TShortsComment[];
  originCommentCount: number;
  totalCommentCount: number;
}

export interface TShortsComment {
  adminId: string | null;
  adminName: string | null;
  content: string;
  createdAt: number;
  customerId: string;
  deletedAt: number | null;
  hiddenAt: number | null;
  id: string;
  isReply: boolean;
  modified: boolean;
  name: string;
  profileUrl: string | null;
  replyCount: number;
  likeCount: number;
  reportedCount: number;
  resourceSrn: string;
  srn: string;
  uid: string;
  updatedAt: number;
  userId: null;
  replies?: TShortsReplies;
  like: boolean;
  adminComment: boolean;
  withdrawn?: boolean;
}

export type TShortsReplies = Omit<TShortsComment, 'replies'>[];

export type TShortsValidateType = 'DEFAULT' | 'ERROR' | 'SUCCESS' | 'EMPTY_ERROR';
export interface TProductBanner {
  title?: string;
  imageUrl?: string;
  scheme?: string;
  traceId: string;
  type: string;
  text?: string;
}

export enum ShortsStatus {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
  RESERVED = 'RESERVED',
}

export enum ReviewStatus {
  PENDING = 'PENDING',
  APPROVAL = 'APPROVAL',
  DECLINED = 'DECLINED',
}
export enum CustomerShortformType {
  일반 = '일반',
  홍보 = '홍보',
  외부 = '외부',
}
export interface TSnsUrls {
  Instagram: string;
  Tiktok: string;
  Youtube: string;
}

export interface TVerifiedCreatorConfig {
  customerCreatorTypes: string[];
  activityCategories: string[];
  snsTypes: string[];
}

export interface TVerifiedCreatorList {
  customerId: string;
  uid: string;
  userId: string;
  shortformCount: number;
  createdAt: number;
  updatedAt: number;
  activateAt: number;
  deactivateAt: null | number;
  creatorStatus: string;
  customerCreatorType: string;
  activityCategories: string[];
  creatorScore: null | number;
  snsUrls: TSnsUrls;
  adminId: string;
  adminName: string;
}

export interface TRegisterVerifiedCreator {
  customerId: string;
  userId: string;
  activateAt: number;
  customerCreatorType: string;
  activityCategories: string[];
  snsUrls: TSnsUrls;
}

export interface TRegisterShortformGroup {
  id?: string;
  customerId: string;
  sellerId: string;
  identifier: null | string;
  title: string;
  alias: string | null;
  thumbnailUrl: string | null;
  uploadAllowedCreatorTypes: string[];
  active: boolean;
  createdAt: number;
  updatedAt: number;
  createAdminName: string;
  updateAdminName: string;
}

export interface TContentCreator {
  activateAt: number;
  activityCategories: string[];
  adminId: string;
  adminName: string;
  createdAt: number;
  creatorScore: number | null;
  creatorStatus: 'ACTIVE' | 'INACTIVE' | 'PENDING';
  customerCreatorType: string;
  customerId: string;
  deactivateAt: number;
  shortformCount: number;
  snsUrls: Record<string, any>;
  uid: string;
  updatedAt: number;
  userId: string;
  displayUserId: string;
  withdrawn?: boolean;
}

export interface TShortformGroup {
  active: boolean;
  activityCategories: string[];
  alias: string | null;
  checksum: string;
  createAdminId: string;
  createAdminName: string;
  createdAt: string;
  customerCreatorTypes: string[];
  customerId: string;
  id: string;
  identifier: string | null;
  main: boolean;
  sellerId: string | null;
  shortformCount: number;
  shortformList?: TShortsList[]; // 선택적 필드
  shortformRandomOrder: boolean;
  snsTypes: string[];
  sortOrder: string;
  srn: string;
  thumbnailUrl: string | null;
  title: string;
  updateAdminId: string;
  updateAdminName: string;
  updatedAt: string;
  uploadAllowedCreatorTypes: string[];
  isFileUpload?: boolean;
}

export interface TUploadAllowedCreatorTypes {
  title: string;
  checked: boolean;
}

export interface ILineChartData {
  chartTitleKey: string;
  graphType: 'LINE' | 'BAR';
  timestamps: string[];
  data: string[];
  dataPrefixKey: string | null;
  dataSuffixKey: string | null;
  dataDisplayFormat: TDataInsightDataDisplayFormat;
  dataNameKey: string;
  summary: string;
  type: string;
}

export interface IViewRanking<T = IRanksInitialData> {
  chartTitleKey: string;
  scoreDataDisplayFormat: TDataInsightDataDisplayFormat;
  ranks: IRanks<T>[];
  scorePrefixKey: string;
  scoreSuffixKey: string;
  rankingCriteria: 'VIEW' | 'TOTAL_VIEWING_TIME' | 'PRODUCT_CLICK' | 'LIKE' | 'SHARE' | 'COMMENT';
  totalCount: string;
  type: string;
}

export type TDataInsightDataDisplayFormat =
  | 'COUNT'
  | 'PEOPLE'
  | 'PERCENT'
  | 'SECONDS'
  | 'SECONDS_TO_HH_MM_SS'
  | 'MILLIS'
  | 'MILLIS_TO_HH_MM_SS_SSS';

export interface IRanks<T = IRanksInitialData> {
  data: T;
  ranking: number;
  score: string;
}

export interface IRanksInitialData {
  averageViewingTime: string;
  backButtonClicks: string;
  collectionEntryViewers: string;
  collectionEntryViews: string;
  commentClicks: string;
  completeViewers: string;
  completeViews: string;
  descriptionClicks: string;
  likeClicks: string;
  muteClicks: string;
  outlinkClicks: string;
  over3SecondsViewers: string;
  over3SecondsViews: string;
  pageViews: string;
  previewEntryViewers: string;
  previewEntryViews: string;
  productClicks: string;
  shareClicks: string;
  shortformId: string;
  tagClicks: string;
  totalViewers: string;
  totalViewingTime: string;
  unmuteClicks: string;
  viewerEnvironment: string;
  viewerType: string;
  shorts: TShorts;
}

export interface IBrandsRank {
  adminId: string;
  adminName: string;
  createdAt: number;
  customerId: string;
  description: string | null;
  height: number;
  id: string;
  identifier: string;
  imageUrl: string;
  name: string;
  updatedAt: number;
  width: number;
}

export interface ICollectionRank {
  active: boolean;
  alias: string | null;
  checksum: string;
  createAdminId: string | null;
  createAdminName: string | null;
  createdAt: number;
  customerId: string;
  id: string;
  identifier: string | null;
  main: boolean;
  sellerId: string | null;
  shortformCount: number | null;
  shortformRandomOrder: boolean;
  sortOrder: string;
  srn: string;
  thumbnailUrl: string | null;
  title: string;
  updateAdminId: string | null;
  updateAdminName: string | null;
  updatedAt: number;
  uploadAllowedCreatorTypes: any | null;
}

export interface IDoughnutChartData {
  IOS_WEB: number;
  WEB: number;
  AOS_APP: number;
  AOS_WEB: number;
  IOS_APP: number;
}

export type TInsightFetcher = [string, Record<string, any>];

export interface TElevenMember {
  status?: any;
  defaultProfileImageUrl: string;
  displayMemberName: string;
  encryptedMemberNo: string;
  expose: boolean;
  hasNickName: boolean;
  nickName: string;
  profileImageFullUrl: string;
  profileImageUrl: string;
  profileModifiable: boolean;
  reviewerUrl: string;
  useImage: boolean;
}

export interface TElevenProduct {
  status?: {
    code?: number;
    message?: string;
    d_message?: string;
  };
  sku: string;
  prdNo: string;
  prdNm?: string;
  prdImg?: { images: TElevenProductImage[]; noImage48Url?: string; noImage64Url?: string; noImage410Url?: string };
  prdPrice?: {
    selPrc: number;
    finalDscPrc: number;
    discountRate: string;
    optPrcText: string;
  };
  selStatCd?: '103' | '104' | '105'; // 103 판매중, 104 품절 105 판매중지
  prdUrl?: string;
  reviewSatisfy?: string;
  reviewCnt?: string;
  sellerAgencyCd?: string;
  selMemNo?: string;
  minorSelCnYn?: 'Y' | 'N';
  rentalPrdYn?: 'Y' | 'N';
  sdispCtgrNo?: number;
  display?: boolean;
}

export interface TElevenProductImage {
  imageSize410Url?: string;
  imageSize64Url?: string;
  imageSize48Url?: string;
  originalUrl: string;
  imgPath?: string;
}
export type TShortformDataInsightSummary = {
  detailViews: string;
  feedViews: string;
  totalViewers: string;
  totalProductClicks: string;
  feedAverageViewingTime: string;
};

export interface IDataInsightInitialType<T = ILineChartData> {
  componentTitleKey: string;
  charts: T[];
  downloadUrls: IDataInsightDownloadUrls;
  downloadable: boolean;
  from: number;
  to: number;
  tooltip: IDataInsightTooltip;
}

export interface ITooltipBlocks {
  contentKey: string;
  titleKey: string;
  verboseKey: string | null;
}

export interface IDataInsightTooltip {
  contentKey: string | null;
  tooltipBlocks: ITooltipBlocks[];
}

export interface IDoughnutChart {
  chartTitleKey: string | null;
  data: IDoughnutChartData;
  totalCount: string;
  type: string;
}

export interface IRankData<T = IRanksInitialData> {
  chartTitleKey: string;
  ranks: IRanks<T>[];
  scorePrefixKey: string;
  scoreSuffixKey: string;
  type: string;
  totalCount: string;
  summary: string;
  rankingCriteria?: ERankingTabs;
  scoreDataDisplayFormat: TDataInsightDataDisplayFormat;
}

export enum ERankingTabs {
  VIEW = 'VIEW',
  TOTAL_VIEWING_TIME = 'TOTAL_VIEWING_TIME',
  PRODUCT_CLICK = 'PRODUCT_CLICK',
  LIKE = 'LIKE',
  SHARE = 'SHARE',
  CREATE_COMMENT = 'CREATE_COMMENT',
}

export interface IDataInsightDownloadUrls {
  csv: string;
  xlsx: string;
}

export interface IProduct {
  currency: string;
  discountPrice: number;
  discountRate: number;
  imageUrl: string;
  name: string;
  originalPrice: number;
  productId: string;
  showPrice: boolean;
  sortOrder: number;
  url: string;
}

export interface IDataInsightProductData {
  clickViewers: string;
  clicks: string;
  product: IProduct;
  productId: string;
  shortformId: string;
  viewerEnvironment: string;
  viewerType: string;
}

export interface IShortformDataInsightConfig {
  useBrand: boolean;
  useCollectionPage: boolean;
  useCreatorProfile: boolean;
  useLinkButton: boolean;
  useMultiCollection: boolean;
  usePreview: boolean;
}

export interface IDataInsightCTRChart {
  charts: ILineChartData[];
  componentSubtitle: string;
  componentSubtitleKey: string;
  componentTitle: string;
  componentTitleKey: string;
  downloadUrls: IDataInsightDownloadUrls;
  downloadable: boolean;
  from: number;
  to: number;
  tooltip: IDataInsightTooltip;
}

export interface IShortformMedia {
  checksum: string;
  createdAt: number | null;
  customerId: string;
  description: string | null;
  height: number;
  id: string;
  imageId: string;
  mediaType: string;
  name: string | null;
  originalUrl: string;
  size: string;
  thumbnails: string | null;
  updatedAt: number | null;
  url: string;
  width: number;
}

export interface IShortformProduct {
  adminId: string;
  adminName: string;
  bidDuration: number | null;
  bidMaximumPrice: number | null;
  bidPolicy: string | null;
  bidStartingPrice: number | null;
  brand: string | null;
  brandIdentifier: string | null;
  createdAt: number;
  ctaButtonTitle: string | null;
  currency: string;
  customDiscountedPrice: number | null;
  customOriginalPrice: number | null;
  customerId: string;
  customerProductId: string;
  description: string;
  discountPercentage: number;
  discountedPrice: number;
  enableAuction: boolean;
  extendedProperties: any | null;
  id: string;
  locked: boolean;
  medias: IShortformMedia[];
  name: string;
  originalPrice: number;
  schemes: string | null;
  seller: string | null;
  showPrice: boolean;
  sku: string;
  supportAuction: boolean;
  tags: string | null;
  updatedAt: number;
  url: string;
}

export enum EShortsType {
  CAMPAIGN = 'CAMPAIGN',
  CARD = 'CARD',
}

export interface TShortsCampaign {
  backgroundUrl: string | null;
  brand: string | null;
  campaignId: string;
  campaignKey: string | null;
  campaignStatus: string | null;
  description: string | null;
  poster2Url: string | null;
  previewLiveUrl: string | null;
  thumbnailUrl: string;
  tags: string[] | null;
  title: string | null;
  videoAspectRatio: number | null;
}
