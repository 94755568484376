import { IMyFunctions, IMyPrivileges, getFunctionsObject, getPrivilegesObject } from '@common/helper/authority';

import { TMainAdministrator } from 'types/administrator';
import { actionType } from './action';
import { getIsEbayHost, getIsYeosimHost } from '@common/utils/phase';
import RegionManager from '@common/regions';

const LOGO_EBAY = 'https://image.us1.shoplive.cloud/keCtCjDZkG3DFqENKzCj/08bbb6cf-594d-46c9-bc39-c2cff54220f5.svg';
const LOGO_SHOPLIVE = 'https://image.shoplive.cloud/uv9CGthPzlvsInZerCw0/81f84d58-3e37-4d34-9bda-321f466f0323.svg';

const isEbayHost = getIsEbayHost();
const isYeosimHost = getIsYeosimHost();

export const initialState: TAuthorityInitialValues = {
  administrator: null as TMainAdministrator | null,
  isGlobalSuperAdmin: false,
  isCustomerSuperAdmin: false,
  isAiClipAdmin: false,
  language: '',
  myPrivileges: {} as IMyPrivileges,
  myFunctions: {} as IMyFunctions,
  isEbayHost: isEbayHost,
  isYeosimHost: isYeosimHost,
  customerLogo: isEbayHost ? LOGO_EBAY : LOGO_SHOPLIVE,
  showPoweredBy: isEbayHost,
  useCampaignRerun: true, // 재방송 사용여부 임시로 AccessKey 로 구분해서 막기로 했음. 우선 이베이 고객사만 막기로 했음.
};

// 방송 캠페인 RERUN 임시로 막아둘 고객사 - 이베이, 이베이DEV, (DEV)7plus1 고객사.
const RERUN_DISABLE_ACCESSKEY = ['keCtCjDZkG3DFqENKzCj', 'HtDjRpy1Gm6pwYAnNzgi', '0F2YA6FtEWAAIva3mg7s'];

type AuthorityAction =
  | { type: typeof actionType.SET_ADMINISTRATOR; payload: TMainAdministrator | null }
  | { type: typeof actionType.SET_LANGUAGE; payload: string };

export interface TAuthorityInitialValues {
  administrator: TMainAdministrator | null;
  isGlobalSuperAdmin: boolean;
  isCustomerSuperAdmin: boolean;
  isAiClipAdmin: boolean;
  language: string;
  myPrivileges: IMyPrivileges;
  myFunctions: IMyFunctions;
  isEbayHost: boolean;
  isYeosimHost: boolean;
  customerLogo: string;
  showPoweredBy: boolean;
  useCampaignRerun: boolean;
}

export const AuthorityReducer = (state: TAuthorityInitialValues, action: AuthorityAction): TAuthorityInitialValues => {
  switch (action.type) {
    case actionType.SET_ADMINISTRATOR:
      const administrator = action.payload as TMainAdministrator;
      const customerLogo = isEbayHost ? LOGO_EBAY : administrator?.currentCustomerAdminLogoUrl || LOGO_SHOPLIVE;
      const showPoweredBy = !isEbayHost && !!administrator?.currentCustomerAdminLogoUrl;
      const useCampaignRerun = !RERUN_DISABLE_ACCESSKEY.includes(administrator?.currentCustomerAccessKey || '');

      if (administrator?.apiEndpoints) {
        RegionManager.saveApiEndPoints(administrator.apiEndpoints);
      }

      return {
        ...state,
        administrator: administrator,
        isGlobalSuperAdmin: administrator?.authority?.roles.includes('GLOBAL_SUPER_ADMIN'),
        isCustomerSuperAdmin: administrator?.authority?.roles[0] === 'CUSTOMER_SUPER_ADMIN',
        isAiClipAdmin: (administrator?.authority?.roles ?? []).includes('CUSTOMER_AI_CLIP_ADMIN'),
        myPrivileges: getPrivilegesObject(administrator?.authority?.privileges) as IMyPrivileges,
        myFunctions: getFunctionsObject(administrator?.authority?.functions) as IMyFunctions,
        customerLogo,
        showPoweredBy,
        useCampaignRerun,
      };

    case actionType.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload as string,
      };

    default:
      return state;
  }
};
