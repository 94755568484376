const getAdminGuidLinkUrl = (language: string) => {
  return `https://docs.shoplive.cloud/docs/${language}/shoplive-guide`;
};
const getDeveloperGuideLinkUrl = (language: string) => {
  return `https://docs.shoplive.cloud/docs/${language}/live-web-before-start`;
};

const getHighlightClipLinkUrl = (language: string) => {
  return `https://docs.shoplive.cloud/docs/${language}/setting-highlight-clips`;
};
export { getAdminGuidLinkUrl, getDeveloperGuideLinkUrl, getHighlightClipLinkUrl };
