import {
  downloadAiClipMp4ClipApi,
  getAiClipMp4ClipProgressApi,
  regenerateAiClipMp4ClipApi,
} from '@api/shoplive/ai-clip';
import { useTranslation } from '@hooks/useTranslation';
import { TAiClipMp4Clip, TAiClipMp4ClipProgress } from '@localTypes/ai-clip';
import { TMultiProgressAiClip } from '@store/multiProgressPopupStore/multiProgressPopupStore';
import { MultiProgressPopupStatus } from '@styles/design-system/multi-progress-popup/MultiProgressPopup';
import { MultiProgressPopupItem } from '@styles/design-system/multi-progress-popup/MultiProgressPopupItem';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { downloadNamedFile } from '@common/utils/ai-clip';
import { AI_CLIP_DOWNLOAD_INTERVAL, aiClipFlag } from '@components/ai-clip/ai-clip.constant';

type Props = {
  aiClipMp4Clip: TMultiProgressAiClip;
  removeItem: (mp4ClipId: string) => void;
  updatePopupStatus: (mp4ClipId: string, popupStatus: MultiProgressPopupStatus) => void;
};

export const AiClipMultiProgressPopupItem: React.FC<Props> = ({ aiClipMp4Clip, removeItem, updatePopupStatus }) => {
  const [popupStatus, setPopupStatus] = useState<MultiProgressPopupStatus>(MultiProgressPopupStatus.READY);
  const [percent, setPercent] = useState<number>(0);

  const { t } = useTranslation();

  useEffect(() => {
    updatePopupStatus(aiClipMp4Clip.mp4ClipId, popupStatus);
  }, [popupStatus]);

  const { mutate: revalidateDownloadAiClipMp4Clip } = useSWR<TAiClipMp4Clip>(
    [
      'downloadAiClipMp4ClipApi(AI_CLIP)',
      aiClipMp4Clip.aiClipId,
      aiClipMp4Clip.aiClipSectionId,
      aiClipMp4Clip.mp4ClipId,
    ],
    () =>
      downloadAiClipMp4ClipApi({
        category: 'AI_CLIP',
        aiClipId: aiClipMp4Clip.aiClipId,
        sectionId: aiClipMp4Clip.aiClipSectionId,
        mp4ClipId: aiClipMp4Clip.mp4ClipId,
      }),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: (data) => {
        if (data.convertStatus === 'COMPLETE') {
          downloadNamedFile(data.mp4Url, aiClipMp4Clip.fileName);

          if (aiClipFlag.preparingDownload) {
            removeItem(aiClipMp4Clip.mp4ClipId);
          } else {
            setPopupStatus(MultiProgressPopupStatus.SUCCESS);
          }
        } else {
          setPopupStatus(MultiProgressPopupStatus.IN_PROGRESS);
        }
      },
    },
  );

  const { data: aiClipMp4Progress } = useSWR<TAiClipMp4ClipProgress>(
    popupStatus === MultiProgressPopupStatus.IN_PROGRESS
      ? [
          'getAiClipMp4ClipProgressApi(AI_CLIP)',
          aiClipMp4Clip.aiClipId,
          aiClipMp4Clip.aiClipSectionId,
          aiClipMp4Clip.mp4ClipId,
        ]
      : null,
    () => getAiClipMp4ClipProgressApi(aiClipMp4Clip.mp4ClipId),
    {
      refreshInterval: (latestData) => {
        const shouldRefresh = latestData?.convertStatus === 'READY' || latestData?.convertStatus === 'IN_PROGRESS';
        return shouldRefresh ? AI_CLIP_DOWNLOAD_INTERVAL : 0;
      },
      onSuccess: (data) => {
        setPercent(data.convertRate);

        if (data.convertStatus === 'COMPLETE') {
          revalidateDownloadAiClipMp4Clip();
        } else if (data.convertStatus === 'FAILED') {
          setPopupStatus(MultiProgressPopupStatus.FAILED);
        }
      },
    },
  );

  const { mutate: regenerateAiClipMp4Clip } = useSWR(
    ['regenerateAiClipMp4ClipApi', aiClipMp4Clip.aiClipId, aiClipMp4Clip.aiClipSectionId, aiClipMp4Clip.mp4ClipId],
    () =>
      regenerateAiClipMp4ClipApi({
        aiClipId: aiClipMp4Clip.aiClipId,
        sectionId: aiClipMp4Clip.aiClipSectionId,
        mp4ClipId: aiClipMp4Clip.mp4ClipId,
      }),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: false,
      onSuccess: () => {
        setPopupStatus(MultiProgressPopupStatus.IN_PROGRESS);
      },
    },
  );

  const wrapRemoveItem = () => {
    removeItem(aiClipMp4Clip.mp4ClipId);
  };

  return (
    <MultiProgressPopupItem
      label={`${aiClipMp4Clip.fileName}.mp4`}
      status={popupStatus}
      percent={percent}
      texts={{
        cancelConfirmTitle: t('multi_progress_popup.modal.download_cancel_title'),
        cancelCompleteToast: t('multi_progress_popup.toast.download_cancel_complete'),
      }}
      onRemoveItem={wrapRemoveItem}
      onRetryItem={regenerateAiClipMp4Clip}
    />
  );
};
