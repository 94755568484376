import { AI_CLIP_MIN_VIDEO_DURATION, aiClipFlag } from '@components/ai-clip/ai-clip.constant';
import {
  MediaConvertOptionPresetCategory,
  TAiClip,
  TAiClipSection,
  TAiClipTemplatePreset,
  VideoOrientation,
  OutputVideoOrientation,
} from '@localTypes/ai-clip';
import { TCampaignVideo } from '@localTypes/campaignVideo';
import { TMaskingClip } from '@localTypes/shortform';
import { TVideo } from '@localTypes/video';
import { isArray, isNil } from 'lodash';
import { RefObject } from 'react';
import { AI_CLIP_HLS_OPTIONS } from './hls';

/**
 * Formatting Milliseconds to mm:ss or hh:mm:ss
 */
export function formatMMSS({ ms, alwaysHours }: { ms: number; alwaysHours?: boolean }): string {
  const hours = Math.floor(ms / 1000 / 3600);
  const minutes = Math.floor(((ms / 1000) % 3600) / 60);
  const seconds = Math.floor((ms / 1000) % 60);

  const withHours = [hours, minutes, seconds];
  const withoutHours = [minutes, seconds];

  let times;

  if (alwaysHours) {
    times = withHours;
  } else {
    times = hours > 0 ? withHours : withoutHours;
  }

  return times.map((v: number) => (v < 10 ? `0${v}` : v)).join(':');
}

/**
 * Formatting Milliseconds to m:ss or h:mm:ss
 */
export function formatMSS({ ms, alwaysHours }: { ms: number; alwaysHours?: boolean }): string {
  const hours = Math.floor(ms / 1000 / 3600);
  const minutes = Math.floor(((ms / 1000) % 3600) / 60);
  const seconds = Math.floor((ms / 1000) % 60);

  const h = hours.toString();
  const mm = minutes.toString().padStart(2, '0');
  const ss = seconds.toString().padStart(2, '0');

  if (alwaysHours) {
    return `${h}:${mm}:${ss}`;
  } else {
    const m = minutes.toString();
    return hours > 0 ? `${h}:${mm}:${ss}` : `${m}:${ss}`;
  }
}

/**
 * @note
 * It doesn't consider file extension that includes dot(.) inside. (e.g. tar.gz)
 */
export function removeFileExtension(fileName: string): string {
  return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
}

/**
 * Most common video file types are:
 * https://blog.filestack.com/complete-list-audio-video-file-formats/
 */
export function hasVideoFileExtension(fileName: string): boolean {
  const extensions = [
    '.WEBM',
    '.MPG',
    '.MP2',
    '.MPEG',
    '.MPE',
    '.MPV',
    '.OGG',
    '.MP4',
    '.M4P',
    '.M4V',
    '.AVI',
    '.WMV',
    '.MOV',
    '.QT',
    '.FLV',
    '.SWF',
  ];

  return extensions.some((ext) => fileName.toUpperCase().endsWith(ext));
}

export function isVideoType(target: unknown): target is TVideo {
  return (target as any)?.videoId !== undefined;
}

export function isCampaignVideoType(target: unknown): target is TCampaignVideo {
  return (target as any)?.campaignId !== undefined;
}

export function validateIsDisabledAiClipVideo({ video }: { video: TVideo | TCampaignVideo }): boolean {
  const isValidDuration = Math.floor(video.duration / 1000) >= AI_CLIP_MIN_VIDEO_DURATION * 60;
  const isValidVideo = isVideoType(video) ? !isNil(video.width) && !isNil(video.height) : true;
  const isConvertFailed = isVideoType(video) ? video.convertStatus === 'FAILED' : false;

  return !isValidDuration || !isValidVideo || isConvertFailed;
}

export const attachAiClipVideo = (videoSrc: string, videoRef: RefObject<HTMLVideoElement>, hlsRef: any) => {
  if (!videoRef || !videoRef.current) {
    return;
  }

  if (window.Hls.isSupported()) {
    const hls = new window.Hls(AI_CLIP_HLS_OPTIONS);

    hls.loadSource(videoSrc);
    hls.attachMedia(videoRef.current);
    hlsRef.current = hls;
  } else {
    videoRef.current.src = videoSrc;
  }
};

export const getUpdatedAiClip = ({ aiClip, updatedSection }: { aiClip: TAiClip; updatedSection: TAiClipSection }) => {
  const copiedSections = isArray(aiClip.sections) ? structuredClone(aiClip.sections) : [];

  return {
    ...structuredClone(aiClip),
    sections: copiedSections.map((section: TAiClipSection) =>
      section.id === updatedSection.id ? updatedSection : section,
    ),
  };
};

// ai clip edit - 구간 1개
export const getAiMaskingClip = (
  {
    startTime,
    endTime,
  }: {
    startTime: number;
    endTime: number;
  },
  rangedSection: TAiClipSection,
) => {
  const maskingArray: any = [];

  const { startTime: rangedStartTime, endTime: rangedEndTime } = rangedSection;

  maskingArray.push({
    startTime: rangedStartTime,
    endTime: startTime,
  });

  maskingArray.push({
    startTime: endTime,
    endTime: rangedEndTime,
  });

  return maskingArray;
};

export const SECTION_WIDTH = 174;
export const MIN_TIME_GAP = 1000;

/**
 * @description
 * clipSection - 구간
 * rangedSection - clipSection(구간)을 기준으로 원하는 시간이 추가된 구간
 */
export const validateTime = {
  start: (clipSection: TAiClipSection, ms: number, rangedSection: TAiClipSection | null) => {
    const { startTime: rangedStartTime } = rangedSection ?? {
      startTime: 0,
    };

    if (ms <= 0) {
      ms = 0;
    }

    // min gap 보다 작아질 수 없음
    if (clipSection.endTime - ms < MIN_TIME_GAP) {
      ms = clipSection.endTime - MIN_TIME_GAP;
    }

    //시작 시간은 끝 구간을 넘을 수 없다.
    if (ms >= clipSection.endTime) {
      ms = clipSection.endTime - MIN_TIME_GAP;
    }

    if (ms < rangedStartTime && rangedStartTime !== 0) {
      ms = rangedStartTime;
    }

    return ms;
  },

  end: (clipSection: TAiClipSection, ms: number, videoDuration: number, rangedSection: TAiClipSection | null) => {
    const { endTime: rangedEndTime } = rangedSection ?? {
      endTime: videoDuration,
    };

    // 끝 시간은 영상 길이를 넣을 수 없다.
    if (ms >= videoDuration) {
      ms = videoDuration;
    }

    // min gap 보다 작아질 수 없음
    if (ms - clipSection.startTime < MIN_TIME_GAP) {
      ms = clipSection.startTime - MIN_TIME_GAP;
    }

    //끝 구간은 시작 시간 이전일 수 없다.
    if (ms <= clipSection.startTime) {
      ms = clipSection.startTime + MIN_TIME_GAP;
    }

    if (ms > rangedEndTime && rangedEndTime !== videoDuration) {
      ms = rangedEndTime;
    }

    return ms;
  },
};

export const getDefaultSliderStep = (videoDuration: number, sectionDuration: number) => {
  const sectionDurationInSeconds = sectionDuration / 1000;
  const videoDurationInSeconds = videoDuration / 1000;
  return Math.round(videoDurationInSeconds / sectionDurationInSeconds / 2.5);
};

/**
 *
 * @note
 * aiclip - startTime, endTime
 * shortform - from, to
 *
 */

export function isAiClipSection(section: TAiClipSection | TMaskingClip): section is TAiClipSection {
  return (section as TAiClipSection).startTime !== undefined;
}

export function downloadNamedFile(url: string, fileName: string) {
  const downloadUrl = new URL(url);
  downloadUrl.searchParams.set('filename', fileName);

  /**
   * 다른 코드에서 beforeunload 이벤트가 설정된 경우, <a> 태그 클릭시 브라우저 Alert 표시되는 이슈.
   * window.onbeforeunload를 임시로 비워두고 다시 설정하는 방식으로 해결
   */
  const beforeUnloadHandler = window.onbeforeunload;
  window.onbeforeunload = null;

  const link = document.createElement('a');
  link.href = downloadUrl.toString();
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);

  window.onbeforeunload = beforeUnloadHandler;
}

/**
 * `sentence`: 문장 단위 자막 (Old), VTT 자막
 * `tokenized`: 단어 단위 자막 (New), ASS 자막
 */
type SubtitleVariant = 'sentence' | 'tokenized';

export function validateSubtitleVariant(section: TAiClipSection | null): SubtitleVariant {
  const hasNoWordsSubtitle = (section?.subtitles ?? []).some(({ words }: any) => isNil(words));
  const subtitleVariant: SubtitleVariant = hasNoWordsSubtitle ? 'sentence' : 'tokenized';

  return subtitleVariant;
}

export type AiClipTemplatePresetAlignment = 'left' | 'center' | 'right';

export function aiClipTemplatePresetUtil({
  videoType,
  videoFilterOption,
  titleOption,
  subtitleOption,
  category,
}: TAiClipTemplatePreset) {
  const input: VideoOrientation = videoType;
  const output: OutputVideoOrientation = videoFilterOption?.videoType ?? input; // 정의된 output이 없다면, input과 동일한 결과물
  const hasCrop = !!videoFilterOption?.cropOption;
  const overlayOption = videoFilterOption?.overlayOption ?? null;

  let presetCategory: MediaConvertOptionPresetCategory;

  if (aiClipFlag.burnedSquare) {
    presetCategory = category;
  } else {
    presetCategory = 'PORTRAIT_TO_PORTRAIT';

    if (input === 'LANDSCAPE' && output === 'LANDSCAPE') {
      presetCategory = 'LANDSCAPE_TO_LANDSCAPE';
    }
    if (input === 'LANDSCAPE' && output === 'PORTRAIT') {
      presetCategory = hasCrop ? 'LANDSCAPE_TO_PORTRAIT_CROP' : 'LANDSCAPE_TO_PORTRAIT_ORIGINAL';
    }
  }

  let presetAlignment: AiClipTemplatePresetAlignment = 'center';

  /**
   * 제목(`titleOption`)은 없는 경우가 있지만, 자막은 항상 존재하므로 자막으로 위치를 결정.
   */
  if (subtitleOption) {
    presetAlignment =
      subtitleOption.marginL < subtitleOption.marginR
        ? 'left'
        : subtitleOption.marginL > subtitleOption.marginR
        ? 'right'
        : 'center';
  }

  return {
    inputVideoOrientation: input,
    outputVideoOrientation: output,
    presetCategory,
    hasTitle: !!titleOption,
    hasSubtitle: !!subtitleOption,
    hasCrop,
    presetAlignment,
    overlayOption,
  } as const;
}
