import { VIDEO_TYPE } from '@common/utils/shortform';
import { RefObject } from 'react';
import { TKeyFrame, TClip } from 'types/shortform';
import { StateCreator } from 'zustand';

export type RangeValue<T> = [T | null, T | null] | null;

type ShortFormState = {
  revalidateShortformType: 'NORMAL' | 'REVALIDATE';
  setRevalidateShortformType: (revalidateShortformType: 'NORMAL' | 'REVALIDATE') => void;

  isShortformLoaded: boolean;
  setShortformLoaded: (isShortformLoaded: boolean) => void;
  isListView: boolean;
  setListView: (isListView: boolean) => void;
  sectionTimeArray: TClip[];
  initSectionTimeArray: (sectionTimeArray: TClip[]) => void;
  addSectionTime: (obj: TClip) => void;
  insertSectionTime: (sectionTimeArray: TClip[]) => void;
  removeSectionTime: (idx: number) => void;
  editStartTime: (idx: number, from: number) => void;
  editEndTime: (idx: number, to: number) => void;
  resetSectionTime: () => void;
  videoDuration: number;
  setVideoDuration: (ms: number) => void;
  editSectionTitle: (idx: number, title: string) => void;
  keyFrameSections: TKeyFrame[];
  setKeyFrameSection: (keyFrameSections: TKeyFrame[]) => void;

  videoClipDuration: number;
  setVideoClipDuration: (videoClipDuration: number) => void;
  selectedClipIndex: number | null;
  setSelectedClipIndex: (selectedClip: number | null) => void;
  isEdited: boolean;
  setIsEdited: (value: boolean) => void;
  isSeeking: boolean;
  setIsSeeking: (value: boolean) => void;
  seekingTime: number;
  setSeekingTime: (value: number) => void;
  previewVideoType: string;
  setPreviewVideoType: (value: string) => void;

  clipRef: RefObject<HTMLVideoElement> | null;
  setClipRef: (clipRef: RefObject<HTMLVideoElement> | null) => void;
  clipSumRef: RefObject<HTMLVideoElement> | null;
  setClipSumRef: (clipSumRef: RefObject<HTMLVideoElement> | null) => void;
  originalRef: RefObject<HTMLVideoElement> | null;
  setOriginalRef: (originalRef: RefObject<HTMLVideoElement> | null) => void;
  isBoxDragging: boolean;
  setIsBoxDragging: (isBoxDragging: boolean) => void;

  isTimeBarMouseDown: boolean;
  setIsTimeBarMouseDown: (isTimeBarMouseDown: boolean) => void;
  isClipMouseDown: boolean;
  setIsClipMouseDown: (isClipMouseDown: boolean) => void;
  barLeftTime: number;
  setBarLeftTime: (barLeftTime: number) => void;
  barRatio: number;
  setBarRatio: (barRatio: number) => void;
  isFocusVideo: boolean;
  setIsFocusVideo: (isFocusVideo: boolean) => void;
  dragBarTime: number;
  setDragBarTime: (dragBarTime: number) => void;
  isClipMoving: boolean;
  setIsClipMoving: (isClipMoving: boolean) => void;
  isAutoScrollOnPlay: boolean;
  setIsAutoScrollOnPlay: (isAutoScrollOnPlay: boolean) => void;
  currentScaleStep: number;
  setCurrentScaleStep: (currentScaleStep: any) => void;
  sliderMax: number;
  setSliderMax: (sliderMax: number) => void;
  isClipDoubleClicked: boolean;
  setIsClipDoubleClicked: (isClipDoubleClicked: boolean) => void;
  prevBarLeft: number;
  setPrevBarLeft: (prevBarLeft: number) => void;
  clipStartTime: number;
  setClipStartTime: (clipStartTime: number) => void;
  // original
  originalBarTime: number;
  setOriginalBarTime: (originalBarTime: number) => void;
  originalVideoTime: number;
  setOriginalVideoTime: (originalVideoTime: number) => void;

  // clip
  clipBarTime: number;
  setClipBarTime: (clipBarTime: number) => void;
  clipVideoTime: number;
  setClipVideoTime: (clipVideoTime: number) => void;

  isEditedOnlyPlay: boolean;
  setIsEditedOnlyPlay: (isEditedOnlyPlay: boolean) => void;

  editCheckedAndPlay: boolean;
  setEditCheckedAndPlay: (editCheckedAndPlay: boolean) => void;

  //정지됐을때의 clip time을 original 에서 쓰기 위한 값
  updatedOriginalTime: number;
  setUpdatedOriginalTime: (updatedOriginalTime: number) => void;

  // slider
  isSliderChanged: boolean;
  setIsSliderChanged: (isSliderChanged: boolean) => void;

  isClipSelectOnly: boolean;
  setIsClipSelectOnly: (isClipSelectOnly: boolean) => void;

  isBarAtClipStart: boolean;
  setIsBarAtClipStart: (isBarAtClipStart: boolean) => void;

  isHandlerDragging: boolean;
  setIsHandlerDragging: (isHandlerDragging: boolean) => void;

  shortformApiEndpoint: string;
  setShortformApiEndpoint: (shortformApiEndpoint: string) => void;

  // 구간 편집 영상 선택 타입 저장

  selectedEditType: 'EDIT' | 'SELECT';
  setSelectedEditType: (selectedEditType: 'EDIT' | 'SELECT') => void;

  isVideoPlaying: boolean;
  pauseVideo: () => void;
  playVideo: () => void;

  isVideoPortrait: boolean;
  setIsVideoPortrait: (isVideoPortrait: boolean) => void;
};

export const createShortFormEditSlice: StateCreator<ShortFormState> = (set) => ({
  isVideoPlaying: false,
  playVideo: () => set({ isVideoPlaying: true }),
  pauseVideo: () => set({ isVideoPlaying: false }),

  isVideoPortrait: true,
  setIsVideoPortrait: (isVideoPortrait: boolean) =>
    set(() => ({
      isVideoPortrait,
    })),

  revalidateShortformType: 'NORMAL',
  setRevalidateShortformType: (revalidateShortformType: 'NORMAL' | 'REVALIDATE') =>
    set(() => ({ revalidateShortformType })),
  selectedEditType: 'EDIT',
  setSelectedEditType: (selectedEditType: 'EDIT' | 'SELECT') =>
    set(() => ({
      selectedEditType,
    })),

  isShortformLoaded: false,
  setShortformLoaded: (isShortformLoaded: boolean) => set({ isShortformLoaded }),
  isListView: true,
  setListView: (isListView: boolean) => set(() => ({ isListView })),
  shortformApiEndpoint: '',
  setShortformApiEndpoint: (shortformApiEndpoint: string) =>
    set(() => ({
      shortformApiEndpoint,
    })),

  isHandlerDragging: false,
  setIsHandlerDragging: (isHandlerDragging: boolean) =>
    set(() => ({
      isHandlerDragging,
    })),
  isBarAtClipStart: false,
  setIsBarAtClipStart: (isBarAtClipStart: boolean) =>
    set(() => ({
      isBarAtClipStart,
    })),
  isClipSelectOnly: false,
  setIsClipSelectOnly: (isClipSelectOnly: boolean) =>
    set(() => ({
      isClipSelectOnly,
    })),
  isSliderChanged: false,
  setIsSliderChanged: (isSliderChanged: boolean) =>
    set(() => ({
      isSliderChanged,
    })),
  updatedOriginalTime: 0,
  setUpdatedOriginalTime: (updatedOriginalTime: number) =>
    set(() => ({
      updatedOriginalTime,
    })),
  editCheckedAndPlay: false,
  setEditCheckedAndPlay: (editCheckedAndPlay: boolean) =>
    set(() => ({
      editCheckedAndPlay,
    })),
  isBoxDragging: false,
  setIsBoxDragging: (isBoxDragging: boolean) =>
    set(() => ({
      isBoxDragging,
    })),

  // clip
  clipBarTime: 0,
  clipVideoTime: 0,
  setClipBarTime: (clipBarTime: number) =>
    set(() => ({
      clipBarTime,
    })),
  setClipVideoTime: (clipVideoTime: number) =>
    set(() => ({
      clipVideoTime,
    })),

  // original
  originalVideoTime: 0,
  setOriginalVideoTime: (originalVideoTime: number) =>
    set(() => ({
      originalVideoTime,
    })),
  originalBarTime: 0,
  setOriginalBarTime: (originalBarTime: number) =>
    set(() => ({
      originalBarTime,
    })),

  clipStartTime: 0,
  setClipStartTime: (clipStartTime: number) =>
    set(() => ({
      clipStartTime,
    })),
  prevBarLeft: 0,
  setPrevBarLeft: (prevBarLeft: number) =>
    set(() => ({
      prevBarLeft,
    })),
  isClipDoubleClicked: false,
  setIsClipDoubleClicked: (isClipDoubleClicked: boolean) =>
    set(() => ({
      isClipDoubleClicked,
    })),
  sliderMax: 0,
  setSliderMax: (sliderMax: number) =>
    set(() => ({
      sliderMax,
    })),
  currentScaleStep: 1,
  setCurrentScaleStep: (currentScaleStep: number) =>
    set(() => ({
      currentScaleStep,
    })),
  isEditedOnlyPlay: false,
  setIsEditedOnlyPlay: (isEditedOnlyPlay: boolean) =>
    set(() => ({
      isEditedOnlyPlay,
    })),
  isAutoScrollOnPlay: true,
  setIsAutoScrollOnPlay: (isAutoScrollOnPlay: boolean) =>
    set(() => ({
      isAutoScrollOnPlay,
    })),
  dragBarTime: 0,
  setDragBarTime: (dragBarTime: number) =>
    set(() => ({
      dragBarTime,
    })),
  isFocusVideo: false,
  setIsFocusVideo: (isFocusVideo: boolean) =>
    set(() => ({
      isFocusVideo,
    })),
  barRatio: 0,
  setBarRatio: (barRatio: number) =>
    set(() => ({
      barRatio,
    })),
  barLeftTime: 0,
  setBarLeftTime: (barLeftTime: number) =>
    set(() => ({
      barLeftTime,
    })),
  isClipMouseDown: false,
  setIsClipMouseDown: (isClipMouseDown: boolean) =>
    set(() => ({
      isClipMouseDown,
    })),
  isTimeBarMouseDown: false,
  setIsTimeBarMouseDown: (isTimeBarMouseDown: boolean) =>
    set(() => ({
      isTimeBarMouseDown,
    })),
  clipRef: null,
  setClipRef: (clipRef: RefObject<HTMLVideoElement> | null) =>
    set(() => ({
      clipRef,
    })),
  clipSumRef: null,
  setClipSumRef: (clipSumRef: RefObject<HTMLVideoElement> | null) =>
    set(() => ({
      clipSumRef,
    })),
  originalRef: null,
  setOriginalRef: (originalRef: RefObject<HTMLVideoElement> | null) =>
    set(() => ({
      originalRef,
    })),
  previewVideoType: VIDEO_TYPE.ORIGINAL,
  setPreviewVideoType: (previewVideoType: string) =>
    set(() => ({
      previewVideoType,
    })),
  seekingTime: 0,
  setSeekingTime: (seekingTime: number) =>
    set(() => ({
      seekingTime,
    })),
  isSeeking: false,
  setIsSeeking: (isSeeking: boolean) =>
    set(() => ({
      isSeeking,
    })),
  selectedClipIndex: null,
  setSelectedClipIndex: (selectedClipIndex: number | null) =>
    set(() => ({
      selectedClipIndex,
    })),
  videoClipDuration: 0,
  setVideoClipDuration: (videoClipDuration: number) =>
    set(() => ({
      videoClipDuration,
    })),
  keyFrameSections: [],
  setKeyFrameSection: (keyFramesSection: TKeyFrame[]) =>
    set(() => ({
      keyFrameSections: keyFramesSection,
    })),
  videoDuration: 0,
  setVideoDuration: (ms: number) =>
    set(() => ({
      videoDuration: ms,
    })),
  resetSectionTime: () =>
    set(() => ({
      sectionTimeArray: [],
    })),
  sectionTimeArray: [],
  initSectionTimeArray: (sectionTimeArray: TClip[]) =>
    set(() => ({
      sectionTimeArray,
    })),
  insertSectionTime: (sectionTimeArray: TClip[]) =>
    set(() => ({
      sectionTimeArray,
    })),
  addSectionTime: (obj: TClip) =>
    set((state: ShortFormState) => ({
      sectionTimeArray: [...state.sectionTimeArray, obj],
    })),
  removeSectionTime: (idx: number) =>
    set((state: ShortFormState) => {
      const changedArray = state.sectionTimeArray.filter((_, sectionIndex: number) => {
        return sectionIndex !== idx;
      });

      return {
        sectionTimeArray: changedArray,
      };
    }),

  editStartTime: (idx: number, from: number) =>
    set((state: any) => {
      const editedArray = state.sectionTimeArray.map((section: TClip, sectionIndex: number) => {
        return sectionIndex === idx
          ? {
              ...section,
              from,
            }
          : section;
      });
      return {
        sectionTimeArray: editedArray,
      };
    }),

  editEndTime: (idx: number, to: number) =>
    set((state: any) => {
      const editedArray = state.sectionTimeArray.map((section: TClip, sectionIndex: number) => {
        return sectionIndex === idx
          ? {
              ...section,
              to,
            }
          : section;
      });

      return {
        sectionTimeArray: editedArray,
      };
    }),

  editSectionTitle: (idx: number, title: string) =>
    set((state: any) => {
      const editedArray = state.sectionTimeArray.map((section: TClip, sectionIndex: number) => {
        return sectionIndex === idx
          ? {
              ...section,
              title,
            }
          : section;
      });

      return {
        sectionTimeArray: editedArray,
      };
    }),

  isEdited: false,
  setIsEdited: (value: boolean) => {
    set((state) => ({ ...state, isEdited: value }));
  },
  isClipMoving: false,
  setIsClipMoving: (isClipMoving: boolean) => {
    set(() => ({
      isClipMoving,
    }));
  },
});
