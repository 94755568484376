import { TAiClipFontStyle, TAiClipTitleFontStyle } from '@localTypes/ai-clip';

export const AI_CLIP_STUDIO_MODAL_CLASSNAME = 'ai-clip-studio-modal-wrap';
export const AI_CLIP_WORD_BLANK_INPUT_CLASSNAME = 'ai-clip-word-blank-input';

export const AI_CLIP_CUSTOM_VIDEO_CONTROLS_CLASSNAME = 'ai-clip-custom-video-controls'; // Using dimmed layer + custom video controls both.

export const AI_CLIP_SECTION_DETAIL_AREA_ID = 'ai-clip-section-detail-area';

export const AI_CLIP_REFRESH_INTERVAL = 10 * 1000; // AI Clip 생성 → 10s
export const AI_CLIP_DOWNLOAD_INTERVAL = 3 * 1000; // AI Clip 다운로드 (Progress) → 3s
export const AI_CLIP_COMPOSITION_INTERVAL = 3 * 1000; // 제목/자막 합성 → 3s

export const AI_CLIP_EDITING_THROTTLE = 500; // 0.5s

export const AI_CLIP_MAX_TITLE = 50;
export const AI_CLIP_MAX_DESCRIPTION = 100;
export const AI_CLIP_SECTION_MAX_TITLE = 50;

export const AI_CLIP_MIN_VIDEO_DURATION = 5; // 5분

export const AI_CLIP_MIN_PLAYBACK_RATE = 0.25; // 최소 재생속도
export const AI_CLIP_MAX_PLAYBACK_RATE = 2; // 최대 재생속도

export const AI_CLIP_RE_RENDERING_TIMEOUT = 100; // Rerendering after Undo or Redo. (Milliseconds)

export const AI_CLIP_MIN_MASKING_PERCENT = 0; // 0%
export const AI_CLIP_MAX_MASKING_PERCENT = 50; // 50%

export const AI_CLIP_MIN_CAP_SUBTITLE_SEGMENT_LENGTH = 1;

export const AI_CLIP_CATEGORY_COLUMN_WIDTH = 180;

export const AI_CLIP_INTRO_LOGO_PERCENT_FROM_BOTTOM = 80; // 기본 인트로 로고 위치 % (percentFromBottom)

export const AI_CLIP_FONT_STYLING = {
  /**
   * 위치
   * Percent From Bottom
   */
  MIN_PERCENT_FROM_BOTTOM: 0,
  MAX_PERCENT_FROM_BOTTOM: 100,
  /**
   * 테두리(굵기)
   * Font Stroke(Thickness)
   */
  MIN_OUTLINE: 0,
  MAX_OUTLINE: 5,
  /**
   * 그림자(깊이)
   * Font Shadow(Depth)
   */
  MIN_SHADOW: 0,
  MAX_SHADOW: 5,
  /**
   * 블러(강도)
   * Font Blur(Strength)
   */
  MIN_BLUR: 0,
  MAX_BLUR: 50,
  /**
   * 박스(크기)
   * Font Box(Size)
   */
  MIN_BOX: 0,
  MAX_BOX: 5,
  /**
   * 제목 표시 지속 시간(ms)
   * Title Display Duration(Millisecond)
   */
  MIN_TITLE_DISPLAY_DURATION: 0,
  /**
   * 색상 투명도
   * Opacity(%)
   */
  MIN_OPACITY: 0,
  MAX_OPACITY: 100,
} as const;

const AI_CLIP_BASE_FONT_STYLE: Omit<TAiClipFontStyle, 'marginV' | 'name'> = {
  fontId: 1, // e.g. Pretendard ExtraBold id is 1.
  fontSize: '16',
  primaryColour: '&H00FFFFFF',
  percentFromBottom: 22.5,
  /**
   * 테두리 (Font Outline)
   */
  enableOutline: true,
  outline: 0.1,
  outlineColour: '&H00000000',
  /**
   * 그림자 (Font Shadow)
   */
  enableShadow: true,
  shadow: 1,
  backColour: '&H00000000',
  /**
   * 블러 (Font Blur)
   */
  enableBlur: true,
  blur: 50,
  /**
   * 박스 (Font Box)
   */
  enableBox: false,
  boxSize: 0,
  boxColour: '&H00000000',
  // 고정
  alignment: 2,
  marginL: 10,
  marginR: 10,
  borderStyle: 1,
};

export const AI_CLIP_DEFAULT_FONT_STYLE: { TITLE: TAiClipTitleFontStyle; SUBTITLE: TAiClipFontStyle } = {
  TITLE: {
    ...AI_CLIP_BASE_FONT_STYLE,
    name: 'Title',
    marginV: 120,
    manuallySetTitleDisplayDuration: true,
    titleDisplayDuration: 3000,
  },
  SUBTITLE: {
    ...AI_CLIP_BASE_FONT_STYLE,
    name: 'Subtitle',
    marginV: 40,
  },
};

export const CHANNEL_TALK_PLUGIN_KEY = 'c0d32a5c-0d70-4bd6-b1ae-87ad315a822d'; // 채널톡 플러그인 키

export const aiClipFlag = {
  debug: false, // 디버그 모드 (배포시에는 항상 false)
  preparingDownload: true, // AI Clip 다운로드 팝업 (준비 완료 상태 제거)
  maxLengthPerSubtitleField: false, // Bulk 생성 폼 > '자막 글자 수' 필드
  burnedSquare: true, // 1:1 비율 영상(정방형)
};

export const aiClipSharedVideoProps: React.VideoHTMLAttributes<HTMLVideoElement> = {
  controls: true,
  playsInline: true,
  autoPlay: true,
  disablePictureInPicture: true,
  draggable: false,
  controlsList: 'noplaybackrate nodownload',
};

export const aiClipSttVersionNameMap = {
  V4: 'Stable',
  V1: 'Dev1',
  V2: 'Dev2',
  V3: 'Dev3',
  V5: 'Dev4',
};

export const aiClipStudioVideoZIndex = {
  maskingLayer: 10,
  subtitle: 20,
  introLogo: 20,
  outroImage: 30,
  controls: 40,
  spinner: 40,
  playbackRate: 50,
  shortcut: 60,
};
